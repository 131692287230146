import { createRouter, createWebHistory } from 'vue-router';
import accueil from './components/accueil.vue'
import nos_services from './components/contenu/nos_services.vue'
import nous_contacter from './components/contenu/nous_contacter.vue'
import politique_confidentialite from './components/contenu/politique_confidentialite.vue'
import login from './components/membre/login.vue'
import sLjytt2O3T from './components/membre/home-co.vue'
import qui_sommes_nous from './components/contenu/qui_sommes_nous.vue'
import tarifs_et_contrats from './components/contenu/tarifs_et_contrats.vue'
import temoignages from './components/contenu/temoignages.vue'

const routes = [
    {path: '/', component: accueil},
    {path: '/services', component: nos_services},
    {path: '/contact', component: nous_contacter},
    {path: '/privacypolicy', component: politique_confidentialite},
    {path: '/login', component: login},
    {path: '/quisommesnous', component: qui_sommes_nous},
    {path: '/tarifs', component: tarifs_et_contrats},
    {path: '/temoignages', component: temoignages},
    {path: '/rHImFxsStqIxEQSYc1N9iipZRuXMmZE2KFGOISKM', component: sLjytt2O3T},
    {path: '/:wrongPath(.*)', component: accueil}
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

export default router;