<template>
    <div id="price-container">
        <div id="tarifs-titre"></div>
        <div id="text-tarifs">
            <h2 class="subtitle_tarifs">Tarifs et contrats</h2>
            <h3 class="border-bottom supp-padding">Tarifs</h3>
            <p class="text-qsn">Nous sommes rémunérées en chèque emploi service universel (<strong>CESU</strong>).</p>
            <p class="text-qsn">Nous avons un statut de salariées, vous êtes notre employeur.</p>
            <p class="text-qsn">
                Pour connaitre nos tarifs, vous pouvez nous contacter directement par téléphone. Vous pouvez aussi consulter le site du CESU pour effectuer une simulation. Les week-ends et jours fériés travaillés sont majorés.<br>
            </p>
            <p>
                Possibilité de recevoir des aides:
                <!-- <ul class="list-price">
                    <li>- A.P.A. (allocation personnalisée d'autonomie)</li>
                    <li>- Aide au répit (permet aux proches aidants des personnes âgées bénéficiant de l'A.P.A., de se reposer ou de dégager du temps)</li>
                </ul> -->
            </p>
            <p class="text-qsn">
                Selon la distance, entre le lieu de résidence de l'auxiliaire de vie et le vôtre, des indemnités kilométriques peuvent se rajouter.<br>
            </p>
            <p class="text-qsn">
                Les dépenses engagées pour l'emploi d'un salarié à domicile ouvrent droit à un crédit d'impôt généralisé à l'ensemble des particuliers employeurs, qu'ils soient soumis ou non à l'impôt sur le revenu.
                Celui s'élève à 50% des dépenses réalisées dans l'année: salaires nets payés et cotisations  sociales prélevées.<br>
            </p>
            <a style="margin-bottom: 25px;" class="text-align" title="Plus d'informations sur le crédit d'impot" target="_blank" rel="noreferrer" href="https://www.impots.gouv.fr/portail/particulier/emploi-domicile">Plus d'informations en cliquant ici (site du gouvernement Français)</a>
            <h3 class="border-bottom supp-padding">Contrats</h3>
            <p class="text-qsn">
                Selon l'accord des deux parties, un contrat à durée déterminée (CDD) ou un contrat à durée indéterminée (CDI) est établi.
                La fin de contrat fait l'objet de mesures administratives et financières.
            </p>
            <a href="https://www.cesu.urssaf.fr/info/accueil.html" title="CESU" target="_blank" rel="noreferrer">Se référer au site du CESU en cliquant ici</a>
        </div> 
        <div>
            <span class="border-span"></span>
            <div class="bloc-all-square">
                <div class="bloc-to-square">
                    <a class="bloc-square bloc1" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                    <a class="bloc-square bloc2" title="Nos services" href="/services">NOS SERVICES</a>
                </div>
                <div class="bloc-to-square">
                    <a class="bloc-square bloc4" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
                    <a class="bloc-square bloc3" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
                </div>
            </div>
            <div class="bloc-all-square-tel">
                <a class="bloc1-tel" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                <a class="bloc2-tel" title="Nos services" href="/services">NOS SERVICES</a>
                <a class="bloc4-tel" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
                <a class="bloc3-tel" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
            </div>
            <span class="border-span2"></span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'price',
}
</script>

<style>
#price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: 1s textY;
}

#tarifs-titre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: center url("../../assets/2508x1672-personne-agee.jpg");
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    width: 100%;
    height: 550px;
    margin-bottom: 30px;
}

.subtitle_tarifs {
    position: relative;
    font-size: 2.5em;
    font-weight: normal;
    background-color: rgb(255, 239, 220);
    box-shadow: 0px 0px 25px 1px rgba(128, 128, 128, 0.5);
    width: 100%;
    text-align: center;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

#text-tarifs {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -85px;
    min-width: 280px;
    max-width: 1000px;
    font-size: 1.1em;
    border-radius: 10px;
}

#text-tarifs p {
    padding-left: 8px;
    padding-right: 8px;
}

.list-price {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;

}

/* Contrats */
.supp-padding {
    padding-bottom: 0px;
    margin-bottom: 15px;
}

@media screen and (max-width: 480px) {
    #tarifs-titre {
        background-attachment: scroll;
    }
}
</style>